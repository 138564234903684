<script setup lang="ts">
// Properties and events.
//
const props = defineProps<{
  title: string
}>()
// Main variables.
//
const title = toRef(props, 'title')
</script>

<template>
  <div class="d-flex xs-block-align-left justify-content-between text-end mb-3">
    <span class="rk-capitalize rk-semi-bold text-start me-2">
      {{ title }}:
    </span>
    <span>
      <slot ref="child"></slot>
    </span>
  </div>
</template>

<style lang="scss"></style>
